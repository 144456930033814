<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'BlogDetail',
});

const { $mopI18n, $urls, $storyblokLivePreview } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const classNameRef = ref(['']);
const { getCmsStory, trackPageView, cmsStoryModelRef, loadingRef } = useMopCms();
const { getBreadcrumbSchema } = useMopSeo();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage } = useMopSeo();
const blogLink: string = $mopI18n.localePath($urls.BLOG_PAGE);

initTransition(classNameRef, loadingRef);

onMounted(async () => {
  await getCmsStory(
    `/career/career-blog/detail/${(route.params.path as string)
      .replace('theme-company/', '')
      .replace('theme-company', '')}`,
  );
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    router.replace($mopI18n.localePath($urls.BLOG_PAGE));
    return;
  }
  trackPageView('Blog');
});

const cmsContentState = computed(() => {
  const careerLink: string = $mopI18n.localePath($urls.CAREER);

  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  const publishedDate: string = cmsStoryModelRef.value.getAttribute('publishedDate');
  const category: string = cmsStoryModelRef.value.getAttribute('category');
  const title: string = cmsStoryModelRef.value.getAttribute('title');
  const subTitle: string = cmsStoryModelRef.value.getAttribute('subTitle');
  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: careerLink,
    },
    {
      name: $mopI18n.t('locale.blog.name'),
      url: blogLink,
    },
    {
      name: title,
      url: route.path,
    },
  ];
  return {
    publishedDate,
    category,
    title,
    subTitle,
    breadcrumbs,
  };
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(cmsContentState?.value?.breadcrumbs || []),
  };
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['blog-item-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <MopBreadcrumb v-if="cmsContentState.breadcrumbs" :breadcrumbs="cmsContentState.breadcrumbs" />
      <div v-if="cmsContentState.publishedDate" class="blog-item-page__published-date">
        {{ $mopI18n.formatDate(cmsContentState.publishedDate) }}
      </div>

      <h1 class="blog-item-page__headline">
        {{ cmsContentState.title }}
      </h1>

      <h2 class="blog-item-page__sub-headline">
        {{ cmsContentState.subTitle }}
      </h2>

      <MopCmsContentElementsSlot id="blog-item-page" :elements="cmsStoryModelRef.getContentElements()" no-padding />

      <NuxtLink class="button button--primary blog-item-page__back-button" :to="blogLink" no-prefetch>
        {{ $mopI18n.t('locale.blog.go_back') }}
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.blog-item-page {
  padding: $space10 $global-padding 0;

  @include apply-upto(small) {
    padding-top: 0;
  }
}

.blog-item-page__published-date {
  @include text-style(strong);

  text-transform: uppercase;
  margin-bottom: $space20;
}

.blog-item-page__headline {
  @include text-style(headline, 0);

  margin: $space20 0;
  text-transform: uppercase;
}

.blog-item-page__sub-headline {
  @include text-style(strong);

  margin: $space15 0;
  text-transform: uppercase;
}

.blog-item-page__back-button {
  margin: $space10 0;
  text-transform: uppercase;
}
</style>
